import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faFacebookMessenger, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'

const ForslagPage = () => {
    return (
        <Layout pageTitle="Förslag &amp; feedback">
            
            <p className="intro">Laddify.se är just nu i beta-version och all konstruktiv feedback välkomnas!</p>
            
			<div className="buttons">
				<a href="https://facebook.com/laddify" target="_blank">
                    <FontAwesomeIcon icon={faFacebookF} />
                    <p>Facebook</p>
                </a>

				<a href="https://m.me/laddify" target="_blank">
                    <FontAwesomeIcon icon={faFacebookMessenger} />
                    <p>Messenger</p>
                </a>

                <a href="https://instagram.com/laddify.se" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} />
                    <p>Instagram</p>
                </a>

                <a href="https://twitter.com/laddifyse" target="_blank">
                    <FontAwesomeIcon icon={faTwitter} />
                    <p>Twitter</p>
                </a>

                <a href="https://www.linkedin.com/company/laddify" target="_blank">
                    <FontAwesomeIcon icon={faLinkedin} />
                    <p>LinkedIn</p>
                </a>

                <a href="mailto:hej@laddify.se" target="_blank">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                    <p>E-post</p>
                </a>
            </div>

            <br/><br/><br/><br/><br/><br/><br/>

            <p style={{textAlign: 'center'}}><b>Är du ett laddnätverk?</b><br/>Läs mer <Link to="/om/for-natverk">här</Link>.</p>
        </Layout>
    )
}

export default ForslagPage